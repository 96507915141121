import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { MyHttpApi, PrivilegeItem, VivaWalletFeeUpdateRequest } from 'utils/api';
import { getSelectedCorporation, privilegeItemFromElement, privilegeToTypeAndName } from 'utils/corporation-util';

@autoinject
export class PosVivaWalletFeeNkPosEdit {
  private fee: VivaWalletFeeUpdateRequest = {
    feeCents: 1,
    feePercentage: 0.1,
    delete: false,
    type: "CLIENT",
  };
  private privilege?: PrivilegeItem;
  private typeAndName = "";
  private isMaster = false;

  constructor(private readonly api: MyHttpApi, private readonly router: Router, private readonly i18n: I18N) {
  }

  async activate(params: { id?: string, isMaster?: string; }) {
    this.isMaster = params.isMaster === "true";
    if (params.id) {
      let fee = await this.api.vivaWalletFeeNkPosById({ id: params.id });
      this.fee = { ...fee, delete: !!fee.deleteTime, type: 'CLIENT' };
      this.privilege = privilegeItemFromElement(fee);
    }
    if (!this.isMaster && !params.id) {
      this.privilege = getSelectedCorporation();
    } else if (this.isMaster) {
      let master = await this.api.vivaWalletFeeNkPosMaster();
      this.fee = { ...master, delete: !!master.deleteTime, type: 'CLIENT' };
      this.privilege = undefined;
    }
    this.typeAndName = await privilegeToTypeAndName(this.api, this.i18n, this.privilege);
  }

  async save(deleted: boolean) {
    await this.api.vivaWalletFeeNkPosUpdate({
      ...this.fee,
      privilegeId: this.privilege?.id,
      type: this.privilege?.type || 'CLIENT',
      delete: deleted,
    });
    this.router.navigateBack();
  }
}
